import React, { useContext } from 'react';
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import { comparisonScorecardWordcloudPinAnswer } from "../../../../common/google-analytics/events";
import { urls } from './const';
import { PinnedData } from "./sentiment-list";
import { WordsData } from "./index";

const AnswerPinControl = ({ conceptId, metric, answer, projectId, gaTrackingId }) => {
  const { pinTitle, pinCount } = useContext(WordsData);
  const { pinnedIds, setPinnedIds } = useContext(PinnedData);

  const linkAnswer = () => {
    const url = urls.link.replace(':concept_id', conceptId);
    comparisonScorecardWordcloudPinAnswer(gaTrackingId, { project_id: projectId, item_id: conceptId });
    const requestParams = {
      source_id: metric.source_id,
      source_type: metric.source_type,
      linked_id: answer.id,
      linked_type: answer.type
    };
    if (metric.parent_answer_id) {
      requestParams.answer_id = metric.parent_answer_id;
    }

    Http.post(url, requestParams).then(
      (http) => {
        if (http.response.status === 200) {
          setPinnedIds([ ...pinnedIds, answer.id ]);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  return (
    <div className="sentiment_control">
      {pinnedIds.length < pinCount &&
        <span
          className="pin-button icon-link -gray -no-text -inactive"
          onClick={ linkAnswer }
          role="button"
          title={ pinTitle }
        >
          <SvgIcon className="icon-link_icon" name="i-pin" />
          <span className="icon-link_text">{pinTitle}</span>
        </span>}
    </div>
  );
};

export default AnswerPinControl;
