import React, { useState, useLayoutEffect } from 'react';
import { filter, find } from 'lodash';
import { findOrInitLoop } from '../../logic/loop';
import SetOfQuestions from './five_point_scale/set_of_questions';
import AttributeRatings from './attribute_ratings';
import WithOpenEnd from './customMetricsOpenEnd/withOpenEnd';

const showCustomWithOpenEnds = (question) => (question.open_end_show);

const FivePointScale = (props) => {
  const autoNextStepForIndex = 1000;
  const {
    questions, nextStep, customMetricConcepts, concept, onlyFirstPage, onlySecondPage, survey,
    result
  } = props;
  const getList = (onOnePage) => (
    filter(
      questions,
      (question) => (
        onOnePage ? !question.display_dedicated_page : question.display_dedicated_page
      )
    )
  );
  const [ questionsOnOnePage ] = useState(() => getList(true));
  const [ questionsOnSeparatePage ] = useState(() => getList(false));
  const [ pagesCount ] = useState(() => (1 + questionsOnSeparatePage.length));
  const [ pageIndex, setPageIndex ] = useState(() => {
    if (onlyFirstPage) {
      return questionsOnOnePage.length ? 0 : autoNextStepForIndex;
    }
    return (questionsOnOnePage.length && !onlySecondPage) ? 0 : 1;
  });
  useLayoutEffect(() => {
    if (pageIndex === autoNextStepForIndex) {
      nextStep();
    }
  }, []);

  const pageWithAll = !pageIndex;
  const submit = () => {
    const canShowSeparatePageQuestions = !customMetricConcepts || (
      !!find(
        customMetricConcepts,
        (el) => el.confirmit_concept_id === concept.confirmit_concept_id
      )
    );
    if (pageIndex < pagesCount - 1 && canShowSeparatePageQuestions && !onlyFirstPage) {
      setPageIndex(pageIndex + 1);
    } else {
      nextStep();
    }
  };
  const baseProps = { ...props, ...{ nextStep: submit, questions: null } };
  delete (baseProps['questions']);

  const separatePageQuestion = () => (
    questionsOnSeparatePage[pageIndex - 1]
  );

  const separatePageQuestionIsGrid = () => (
    separatePageQuestion().kind === 'Grid'
  );

  const currentLoop = findOrInitLoop(concept.confirmit_concept_id, result.loops);

  return (
    <>
      {
        pageIndex !== autoNextStepForIndex &&
        <>
          {
            pageWithAll &&
            <SetOfQuestions { ...baseProps } questions={ questionsOnOnePage } survey={ survey } />
          }
          {
            !pageWithAll && separatePageQuestionIsGrid() &&
            <AttributeRatings
              { ...baseProps }
              title={ separatePageQuestion().text }
              questions={ [ questionsOnSeparatePage[pageIndex - 1] ] }
              languageCode={ 'en' }
            />
          }
          {
            !pageWithAll && !separatePageQuestionIsGrid() &&
            <>
              {
                showCustomWithOpenEnds(questionsOnSeparatePage[pageIndex - 1]) ? (
                  <WithOpenEnd
                    question={ questionsOnSeparatePage[pageIndex - 1] }
                    nextStep={ baseProps.nextStep }
                    mainResult={ result }
                    result={ currentLoop }
                  />
                ) : (
                  <SetOfQuestions
                    { ...baseProps }
                    questions={ [ questionsOnSeparatePage[pageIndex - 1] ] }
                  />
                )
              }
            </>
          }
        </>
      }
    </>
  );
};

export default FivePointScale;
