import React, { useState, useRef, useContext, useEffect } from "react";
import { filter, find, map, each } from 'lodash';
import Http from '../../../../common/http';
import { IndexData } from '../index-context';
import { checkTimeout, checkResult, createSummary, updateSummary } from '../../common/summary-panel/http-helpers';
import Checkbox from "./checkbox";
import Working from './working';
import NoData from './noData';
import WithData from "./withData";
import SummaryEditor from "./summaryEditor";
import Note from './note';

const Index = ({
  metric, summaryClassName, entityTitle,
  onGotSummary, answers, slidePrefix, innerFilter
}) => {
  const [ working, setWorking ] = useState(false);
  const [ disabled, setDisabled ] = useState(false);

  const [ attempt, setAttempt ] = useState(0);
  const attemptRef = useRef(attempt);
  attemptRef.current = attempt;

  const [ showModalForm, setShowModalForm ] = useState(false);

  const { data, aiSummaries, setAiSummaries, aiSummaryAvailable } = useContext(IndexData);

  const summary = find(aiSummaries, (el) => (
    el.source_id === metric.source_id && el.source_type === metric.source_type && el.tag === metric.tag
  ));

  const onSlideSwitch = (v) => {
    update({ add_to_report: v });
  };

  const additionalRequestParams = () => {
    const answers = map(
      filter(metric.answers, (answer) => !answer.hidden),
      (item) => item.answer
    ).join("\n");

    return { answers };
  };

  useEffect(() => {
    if (summary && summary.jid) {
      setWorking(true);
      setAttempt(0);
      checkResult(
        [ { id: summary.id, jid: summary.jid } ],
        setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking,
        { onGotSummary, unlimitedMaxAttempts: true }
      );
    }
  }, []);

  const runAI = () => {
    setWorking(true);
    setAttempt(0);

    createSummary(
      metric,
      (http) => {
        setTimeout(() => {
          checkResult(
            [ http.data ],
            setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking,
            { onGotSummary, unlimitedMaxAttempts: true }
          );
        }, checkTimeout);
      },
      () => {
        setWorking(false);
      },
      additionalRequestParams()
    );
  };

  const handleUpdateSummary = () => {
    setWorking(true);
    setAttempt(0);

    updateSummary(
      summary.id,
      (http) => {
        setTimeout(() => {
          checkResult(
            [ http.data ],
            setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking,
            { onGotSummary, unlimitedMaxAttempts: true }
          );
        }, checkTimeout);
      },
      () => {
        setWorking(false);
      },
      additionalRequestParams()
    );
  };

  const update = (params) => {
    setDisabled(true);
    Http.put(`/express/api/ai_summaries/${summary.id}`, params).then(
      (http) => {
        if (http.response.status === 200) {
          if (showModalForm) {
            setShowModalForm(false);
          }
          if ('summary' in params) {
            summary.summary = params.summary;
          }
          if ('add_to_report' in params) {
            summary.add_to_report = params.add_to_report;
          }
          if ('raw_data' in params) {
            summary.raw_data = http.data.record.raw_data;
            summary.raw_data_hashes = http.data.record.raw_data_hashes;
            data.ai_summaries = aiSummaries;
          }
          summary.edited_at_by = http.data.record.edited_at_by;
          setAiSummaries([ ...aiSummaries ]);
        }
        setDisabled(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const handleEditClick = () => {
    setShowModalForm(true);
  };

  const cancelModal = () => {
    setShowModalForm(false);
  };

  useEffect(() => {
    cancelModal();
  }, [ metric ]);

  const saveModalData = (newSummary) => {
    const result = {};
    each(newSummary, (el, index) => {
      result[index] = JSON.stringify(el);
    });

    update({ raw_data: result });
  };

  const onChangeAddToReport = () => {
    update({ add_to_report: !summary.add_to_report });
  };

  const inProgress = working || summary && !!summary.jid;

  let defineTargetTitle;

  if (metric.source_type === 'Focus::MarkupOption') {
    const titlePart = 'Concept Focus';
    defineTargetTitle = (
      metric.reaction === 'positive' ? `${titlePart} (Positive)` : `${titlePart} (Negative)`
    );
  } else if (metric.tag === 'open-end-claim') {
    defineTargetTitle = 'Thought Bubble';
  } else {
    defineTargetTitle = metric.title || entityTitle;
  }

  return (
    <>
      {aiSummaryAvailable &&
        <div className="summary-panel comparisons_plate -bordered -dark -js-scorecard -no-flex">
          <div className="comparisons_section">
            <h3 className="comparisons_plate-title -colored -bigger">AI Summary</h3>
            <Checkbox
              key={ `${slidePrefix}_${innerFilter}-report` }
              title="Add to Report"
              className="-ai"
              value={ summary?.add_to_report || false }
              paramName={ `${slidePrefix}_${innerFilter}` }
              onChange={ onSlideSwitch }
              disabled={ inProgress || !summary }
            />
          </div>
          <div className="ai-summary-inner-wrapper">
            { inProgress && <Working stubText="Working ..." />}

            {!inProgress && !summary &&
            <NoData
              runAI={ runAI }
              text={ `Get AI summary of "${defineTargetTitle}"` }
            />
            }

            {
              !inProgress && summary &&
              <WithData
                summary={ summary }
                handleOnChange={ onChangeAddToReport }
                handleEditClick={ handleEditClick }
                handleUpdateSummary={ handleUpdateSummary }
                disabled={ disabled }
                className={ summaryClassName }
              />
            }

            {
              showModalForm &&
              <SummaryEditor
                cancelModal={ cancelModal }
                saveModalData={ saveModalData }
                disabled={ disabled }
                summaryDataInit={ summary.raw_data }
                allAnswers={ answers }
              />
            }
          </div>
          { !inProgress && summary && <Note /> }
        </div>
      }
    </>
  );
};

export default Index;
