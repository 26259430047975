import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const VisibilityGroups = () => {
  const { initialData, paths, userGroupsActive } = useContext(BaseContext);
  const canManageVisibilityGroups = initialData.can_manage_visibility_groups;
  const visibilityGroupsUrl = paths.users_visibility_groups;

  return (
    <>
      {
        canManageVisibilityGroups &&
        <li className={ classnames("sidebar_nav-list-item", { '-active': userGroupsActive }) }>
          <a
            className="sidebar_nav-link -with-icon"
            href={ visibilityGroupsUrl }
          >
            <svg className="sidebar_nav-link-icon icon -menu-downloads">
              <use xlinkHref="#svg_menu-downloads" />
            </svg>
            <span className="sidebar_nav-link-text">User Groups</span>
          </a>
        </li>
      }
    </>
  );
};

export default VisibilityGroups;
