import React from "react";
import { map } from "lodash";
import { getConditionForQuestion } from "../helper";

export const HiddenFieldsConditions = ({ question, metricType }) => {
  const conditionValue = (condition) => (
    question.kind === "Grid" ? JSON.stringify(condition || "") : (condition || "")
  );
  const conditionsList = getConditionForQuestion(question);
  return (
    <>
      {
        map(conditionsList, (condition, index) => (
          <input
            key={ `condition${index}${question.kind}` }
            type="hidden"
            name={ `project[${metricType}][][condition][${index}]` }
            value={ conditionValue(condition) }
          />
        ))
      }
    </>
  );
};

export const HiddenGridItemFields = ({ question, metricType }) => (
  <>
    {
      map(question.scales, (item, index) => (
        <input
          key={ `grid_item${index}` }
          type="hidden"
          name={ `project[${metricType}][][scales][${index}]` }
          value={ item || '' }
        />
      ))
    }
  </>
);

export const HiddenAnswersSubgroupsFields = ({ question, metricType }) => (
  <>
    {
      map(question.answers_subgroups, (item, index) => (
        <input
          key={ `answers_subgroup${item.id}${index}` }
          type="hidden"
          name={ `project[${metricType}][][answers_subgroups][${item.id}]` }
          value={ JSON.stringify(subgroups(item, question)) }
        />
      ))
    }
  </>
);

const subgroups = (item, question) => {
  if ((item.id === -1 && !question['other_answer']) || (item.id === -2 && !question['none_answer'])) {
    return [];
  }

  return item?.subgroups || '[]';
};

export const HiddenPinnedAnswersFields = ({ question, metricType }) => (
  <>
    {
      map(question.pinned_answers, (item, index) => (
        <input
          key={ `pinned_answers${index}` }
          type="hidden"
          name={ `project[${metricType}][][pinned_answers][${index}]` }
          value={ question.answers_order === 'specific' ? false : (item?.pinned || false) }
        />
      ))
    }
  </>
);

export const StepField = ({ options }) => (
  <input type="hidden" name="step" id="step" value={ options.step } />
);

export const HiddenBaseFields = ({ question, metricType }) => (
  <>
    <input type="hidden" name={ `project[${metricType}][][id]` } value={ question.id } />
    <input type="hidden" name={ `project[${metricType}][][kind]` } value={ question.kind || '' } />
    <input type="hidden" name={ `project[${metricType}][][text]` } value={ question.text || '' } />
    <input type="hidden" name={ `project[${metricType}][][answers_order]` } value={ question.answers_order || '' } />
    <input type="hidden" name={ `project[${metricType}][][none_answer]` } value={ question.kind === "Grid" ? 'false' : question.none_answer || 'false' } />
    <input type="hidden" name={ `project[${metricType}][][none_answer_text]` } value={ question.kind === "Grid" ? '' : question.none_answer_text || '' } />
    <input type="hidden" name={ `project[${metricType}][][other_answer]` } value={ question.kind === "Grid" ? 'false' : question.other_answer || 'false' } />
    <input type="hidden" name={ `project[${metricType}][][other_answer_text]` } value={ question.kind === "Grid" ? '' : question.other_answer_text || '' } />
    <input type="hidden" name={ `project[${metricType}][][multi_condition]` } value={ question.multi_condition || 1 } />
    <input type="hidden" name={ `project[${metricType}][][display_dedicated_page]` } value={ question.display_dedicated_page || 'false' } />
    <input type="hidden" name={ `project[${metricType}][][survey_location]` } value={ question.survey_location || 'default' } />
    <input type="hidden" name={ `project[${metricType}][][_destroy]` } value={ question._destroy || '' } />
    <input type="hidden" name={ `project[${metricType}][][position]` } value={ `${question.position}` || '' } />
    <input type="hidden" name={ `project[${metricType}][][subgroups]` } value={ `${question.subgroups}` || [] } />
    <input type="hidden" name={ `project[${metricType}][][show_stimuli]` } value={ `${question.show_stimuli}` || 'false' } />
  </>
);

export const HiddenQuestionAnswers = ({ question, metricType }) => (
  <>
    {
      map(question.answers, (answer, ai) => (
        <input
          key={ `answers${ai}` } type="hidden" name={ `project[${metricType}][][answers][]` }
          value={ answer || '' }
        />
      ))
    }
  </>
);
