import { each, find, keys, flatten, includes } from 'lodash';
import Http from '../../../../common/http';
import { splitByLast } from "../../../../common/string_utils";

const getLoopData = (project, survey_data, loops, questionFieldsToLookUp = []) => {
  const loop_data = [];
  each(loops, (loop) => {
    survey_data[`${project.concept_field}_${loop.confirmit_concept_id}`] = true;
    const temp = { loop_field: project.loop_field };
    each(loop.answers, (answerId) => {
      if (typeof answerId === 'object') {
        const key = keys(answerId);
        temp[key] = answerId[key];
      } else {
        const parts = answerId.split('_'),
          question = getQuestion(project, parts, questionFieldsToLookUp);
        if (parts.length === 3) {
          temp[`${parts[0]}_${parts[1]}`] = parts[2];
        } else if (question?.kind === 'Multi') {
          temp[`${parts[0]}_${parts[1]}`] = true;
        } else {
          temp[parts[0]] = parts[1];
        }
      }
    });
    each(loop.otherAnswers, (value, key) => {
      temp[`${key}_otherAnswer`] = value;
    });
    temp[project.loop_field] = loop.confirmit_concept_id;
    loop_data.push(temp);
  });
  return loop_data;
};

const getQuestion = (project, parts, questionFieldsToLookUp) => {
  const {
    demographics, optional_demographics, screenings, brand_screenings, category_questions,
    brand_aware_questions, brand_user_questions, custom_questions, survey_metrics, scorings
  } = project;

  const list = [
    ...demographics,
    ...optional_demographics,
    ...screenings,
    ...(brand_screenings || []),
    ...(category_questions || []),
    ...(brand_aware_questions || []),
    ...(brand_user_questions || []),
    ...(custom_questions || []),
    ...(survey_metrics || []),
    ...(scorings || [])
  ];
  if (questionFieldsToLookUp) {
    each(questionFieldsToLookUp, (questionsField) => {
      list.push(project[questionsField] || []);
    });
  }

  return find(flatten(list), (el) => (el.name === parts[0]));
};

const getSurveyData = (project, answers, result, questionFieldsToLookUp = []) => {
  const survey_data = {};
  each(answers, (answerId) => {
    if (typeof answerId === 'object') {
      const key = keys(answerId);
      survey_data[key] = answerId[key];
    } else {
      const parts = splitByLast(answerId, '_');
      let subParts = null;
      if (includes(parts[0], "_") && !includes(parts[0], "BRAND")) {
        subParts = splitByLast(parts[0], '_');
      }
      const question = getQuestion(project, subParts || parts, questionFieldsToLookUp);

      if (question) {
        if ([ 'Single', 'Grid' ].includes(question.kind)) {
          survey_data[parts[0]] = parts[1];
        } else {
          survey_data[answerId] = true;
        }
      } else if (parts.length) {
        survey_data[parts[0]] = parts[1];
      }
    }
  });
  survey_data['xAge'] = (result || {}).selectedAge;
  each((result || {}).otherAnswers, (value, key) => {
    if (typeof value === 'object') { // When custom metric question
      each(value, (otherAnswerValue, conceptId) => {
        survey_data[`${key}_otherAnswer_${conceptId}`] = otherAnswerValue;
      });
    } else { // When screening question
      survey_data[`${key}_otherAnswer`] = value;
    }
  });
  survey_data['antiFraud'] = project.anti_fraud;
  survey_data['pasteDetected'] = result?.pasteDetected;
  survey_data['tags'] = result?.tags;
  return survey_data;
};

const saveUserDataBase = (
  url, status, result, data,
  moreParams,
  moreSurveyData,
  questionFieldsToLookUp = []
)  => {
  const surveyData = {
    ...getSurveyData(data, result.answers, result, questionFieldsToLookUp),
    ...(moreSurveyData || {})
  };
  const loopData = getLoopData(data, surveyData, result.loops, questionFieldsToLookUp);

  const postParams = {
    ...{
      confirmit_respondent_id: data.respondent_id,
      survey_status: status || 'complete',
      survey_data: surveyData,
      loop_data: loopData
    },
    ...(moreParams || {})
  };
  Http.post(url, postParams).then(
    (response) => {
      if (response && response.data && response.data.redirect) {
        window.location = response.data.redirect;
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export {
  getLoopData,
  getSurveyData,
  saveUserDataBase
};
