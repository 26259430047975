import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import cn from 'classnames';
import Http from "../../../../../common/http";
import { IndexData } from '../../index-context';
import { comparisonScorecardWordcloudShowSentimentsChange } from "../../../../common/google-analytics/events";
import SummaryPanelWithEditing from '../../summaryPanelWithEditing/index';
import RightPanel from "./right-panel";
import LeftPanel from "./left-panel";
import { WithExchangeData } from "./exchange-data";

export const WordsData = createContext({});

const Index = ({
  metric, customHeaderMainClass, className, customTitle, cardData,
  filter, answerHelp, isAdmin, loadWords, loadComments,
  pinTitle, unpinTitle, pinCount, tourName
}) => {
  const [ sentiment, setSentiment ] = useState(metric.sentiment);
  const [ loading, setLoading ] = useState(false);
  const tourLeftElement = useRef(null);
  const tourRightElement = useRef(null);
  const tourSwitchElement = useRef(null);
  const { init, getTourUseEffectArgs } = useContext(IndexData);
  const baseTourName = tourName || 'projectModalCF';
  const [ leftCallback, leftCondition ] = getTourUseEffectArgs(
    `${baseTourName}Left`, tourLeftElement
  );
  const [ rightCallback, rightCondition ] = getTourUseEffectArgs(
    `${baseTourName}Right`, tourRightElement
  );
  const [ switchCallback, switchCondition ] = getTourUseEffectArgs(
    `${baseTourName}Switch`, tourSwitchElement
  );
  useEffect(leftCallback, leftCondition);
  useEffect(rightCallback, rightCondition);
  useEffect(switchCallback, switchCondition);

  const update = (event) => {
    comparisonScorecardWordcloudShowSentimentsChange(
      init.google_analytics_id,
      { project_id: init.project.id, item_id: metric.concept_id });
    setSentiment(event.target.checked);
    metric.sentiment = event.target.checked;
    setLoading(true);

    Http.put(
      metric.urls.update,
      {
        sentiment: event.target.checked,
        orientation: metric.orientation,
        tag: metric.tag
      }
    ).then(
      (http) => {
        // if (http.response.status === 200) {}
        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const value = {
    loadWords,
    loadComments,
    pinTitle,
    unpinTitle,
    pinCount
  };

  return (
    <WordsData.Provider value={ value }>
      <div className={ cn("page-container -with-bottom-margin -wordcloud", className) }>
        <div className="header-with-controls -description -in-page-container">
          <div
            className={
              cn(
                'header-with-controls_main',
                customHeaderMainClass
              )
            }
          >
            {
              customTitle ? (
                <div>{customTitle}</div>
              ) : (
                <h3 className="header-with-controls_title">
                  { !!metric.name && <span>[{metric.name}]</span> }
                  { metric.title }
                </h3>
              )
            }
            <label
              className="form-switch-group -no-border switch-sentiments"
              ref={ tourSwitchElement }
            >
              <input
                type="checkbox"
                checked={ sentiment }
                onChange={ update }
                className="hide"
              />
              <span className="form-switch" />
              Show Sentiment Analysis
            </label>
          </div>
        </div>
        <div className="resp_number">
          N = {cardData.respondents_number}
        </div>

        <SummaryPanelWithEditing metric={ metric } />

        <div className="tiles_row -wordcloud">
          { loading && <div className="loading-back" /> }

          <WithExchangeData>
            <div className="tile -sentiments-side">
              <div className="tile_inset -with-border" ref={ tourLeftElement }>
                <LeftPanel
                  data={ cardData }
                  metric={ metric }
                  filter={ filter }
                  sentiment={ sentiment }
                  tourName={ baseTourName }
                />
              </div>
            </div>
            <div className="tile -sentiments-main">
              <div className="tile_inset -with-border" ref={ tourRightElement }>
                <RightPanel
                  data={ cardData }
                  metric={ metric }
                  answerHelp={ answerHelp }
                  filter={ filter }
                  isAdmin={ isAdmin }
                  setLoading={ setLoading }
                />
              </div>
            </div>
          </WithExchangeData>
        </div>
      </div>
    </WordsData.Provider>
  );
};

export default Index;
