import React, { Fragment, useState, useEffect } from 'react';
import { map, compact, values, flatten } from 'lodash';
import HeaderLinks from "../header-links";
import ExpressOpenEndCard from "../express-open-end-card";
import ImageInPopup from '../../../../../common/components/image_in_popup';

const BasePrTitleTag = ({ title, question, token, name, n, imageUrl }) => {
  let text = question;
  if (!!token && !!name) {
    text = text.replace(token, name);
  }

  return (
    <div className="reasons-pref-header header-with-controls_custom-header">
      <h3>{ title }</h3>
      {
        !!imageUrl &&
        <ImageInPopup
          className="scorecard-reason-image -pointer"
          customZIndex={ 1062 }
          src={ imageUrl }
        >
          <img className="-pointer" src={ imageUrl } alt="OptionImage" />
        </ImageInPopup>
      }
      { !!text && <p>{ text }</p> }
      <p>N={n}</p>
    </div>
  );
};

const CustomTbTitleTag = (props) => (<BasePrTitleTag title="Thought Bubble" { ...props } />);
const CustomPrTitleTag = (props) => (<BasePrTitleTag title="Reasons for Preference" { ...props } />);

const ReasonsForPreference = ({
  customOpenEnds, preferenceReasons, tbEnabled, current, reports, init, data,
  loadCurrent, loadComments, isAdmin,
  replaceToken = '%currentName%'
}) => {
  const listCustomOpenEndCards = () => compact(
    map(
      (customOpenEnds || {}),
      (openEndData, openEndName) => (
        openEndData[current.id] || []
      )
    )
  );
  const [ customOpenEndCards, setCustomOpenEndCards ] = useState(listCustomOpenEndCards);
  useEffect(() => {
    setCustomOpenEndCards(listCustomOpenEndCards());
  }, [ customOpenEnds ]);

  const bubblesText = () => {
    const resultArr = [];
    const customOpenEndLen = customOpenEndCards.length;

    if (tbEnabled) {
      resultArr.push("Thought Bubble");
    }
    if (preferenceReasons) {
      resultArr.push("Reasons for Preference");
    }
    if (customOpenEndLen) {
      resultArr.push("Custom Open Ends");
    }

    const resultText = resultArr.join(' & ');

    if (resultArr.length) {
      const addition = `(Word Cloud${(resultArr.length > 1 || customOpenEndLen > 1) ? 's' : ''})`;
      return `${resultText} ${addition}`;
    }
    return "";
  };

  const demographics = data.filter.demographics;
  const enabledOpenEnds = (tbEnabled || preferenceReasons || customOpenEndCards.length);
  const prCard = current.preference_card;
  const tbCard = current.open_end_card;

  return (
    <>
      {
        enabledOpenEnds &&
        <div className="comparisons_plate -margin">
          <div className="comparisons_plate-grid">
            <div className="comparisons_plate-item">
              <h3 className="comparisons_plate-title">
                {bubblesText()}
              </h3>
            </div>
            <div className="comparisons_plate-item">
              <HeaderLinks
                className="-no-min-width"
                exportUrl={ init.urls.export }
                reports={ reports }
                i18n={ init.i18n }
                data={ data }
                conceptId={ current.id }
                tourName={ "projectTbExport" }
              />
            </div>
          </div>
        </div>
      }
      {
        !!tbEnabled && !!current.open_end_card &&
        <div className="concept-report_tab wordcloud js-tb">
          <ExpressOpenEndCard
            metric={ tbCard.metric }
            cardData={ tbCard.data }
            mode="editing"
            filter={ demographics }
            customTitle={ (
              <CustomTbTitleTag
                question={ data.questions.thought_bubble_title }
                token={ replaceToken }
                name={ current.title }
                n={ tbCard.data.respondents_number }
              />) }
            pinCount={ 1 }
            answerHelp={ data.translations.wordcloud_hint }
            pinTitle={ data.translations.wordcloud_pin }
            unpinTitle={ data.translations.wordcloud_unpin }
            isAdmin={ isAdmin }
            loadWords={ loadCurrent }
            loadComments={ loadComments }
            tourName={ 'projectModalTB' }
          />
        </div>
      }
      {
        !!preferenceReasons && !!current.preference_card &&
        <div className="concept-report_tab wordcloud js-pc">
          <ExpressOpenEndCard
            metric={ prCard.metric }
            cardData={ prCard.data }
            mode="editing"
            filter={ demographics }
            customTitle={
              <CustomPrTitleTag
                question={ data.questions.reasons_for_preference_title }
                token={ replaceToken }
                name={ current.title }
                n={ prCard.data.respondents_number }
              />
            }
            pinCount={ 1 }
            answerHelp={ data.translations.wordcloud_hint }
            pinTitle={ data.translations.wordcloud_pin }
            unpinTitle={ data.translations.wordcloud_unpin }
            isAdmin={ isAdmin }
            loadWords={ loadCurrent }
            loadComments={ loadComments }
            tourName={ 'projectModalReasons' }
          />
        </div>
      }
      {
        map(customOpenEndCards, (customOpenEndCardsList, customQuestionName) => (
          <Fragment key={ customQuestionName }>
            {
              map(flatten(values(customOpenEndCardsList)), (customOpenEndCard) => (
                <div
                  className="concept-report_tab wordcloud js-custom-card"
                  key={ `card_${customQuestionName}_${customOpenEndCard.metric.parent_answer_id}` }
                >
                  <ExpressOpenEndCard
                    metric={ customOpenEndCard.metric }
                    cardData={ customOpenEndCard.data }
                    mode="editing"
                    filter={ demographics }
                    customTitle={
                      <BasePrTitleTag
                        title={ `Custom Reason - ${customOpenEndCard.metric.q_name} (${customOpenEndCard.metric.parent_human_answer_id})` }
                        question={ customOpenEndCard.metric.title }
                        imageUrl={ customOpenEndCard.metric.parent_answer_image }
                        n={ customOpenEndCard.data.respondents_number }
                      />
                    }
                    pinCount={ 1 }
                    answerHelp={ data.translations.wordcloud_hint }
                    pinTitle={ data.translations.wordcloud_pin }
                    unpinTitle={ data.translations.wordcloud_unpin }
                    isAdmin={ isAdmin }
                    loadWords={ loadCurrent }
                    loadComments={ loadComments }
                    tourName={ 'projectModalCustomOpenEnd' }
                  />
                </div>
              ))
            }
          </Fragment>
        ))
      }
    </>
  );
};

export default ReasonsForPreference;
