import { each, find, filter } from "lodash";
import Http from "../../../../common/http";

const checkTimeout = 3000;
const maxAttempts = 20;

const checkResult = (
  idsJids, setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking, options = {}
) => {
  const { onGotSummary, unlimitedMaxAttempts } = (options || {});

  setAttempt(attemptRef.current + 1);
  Http.post('/express/api/ai_summaries/check_status', { ids: idsJids }).then(
    (http) => {
      if (http.response.status === 200) {
        // eslint-disable-next-line lodash/prefer-filter
        each(http.data, (record) => {
          if (record.status === 'complete') {
            const summary = find(aiSummaries, (el) => (el.id === record['id']));

            if (summary) {
              summary.summary = record.record.summary;
              summary.edited_at_by = record.record.edited_at_by;
              summary.jid = null;
              summary.raw_data = record.record.raw_data;
              summary.tag = record.record.tag;
            } else {
              aiSummaries.push(record.record);
            }
            onGotSummary && onGotSummary(record.record);
          }
        });
        setAiSummaries([ ...aiSummaries ]);

        const nextCheckIds = filter(http.data, (el) => (el.status !== 'complete'));
        const attemptsDone = (unlimitedMaxAttempts ? false : attemptRef.current > maxAttempts);

        if (!nextCheckIds.length || attemptsDone) {
          setWorking(false);
        } else {
          setTimeout(() => {
            checkResult(
              nextCheckIds, setAttempt, attemptRef, aiSummaries, setAiSummaries, setWorking, options
            );
          }, checkTimeout);
        }
      } else {
        setWorking(false);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const createSummary = (metric, goodCallback, badCallback, additionalParams = {}) => {
  const params = {
    ...{
      project_id: metric.project_id,
      database_concept_id: metric.concept_id,
      source_id: metric.source_id,
      source_type: metric.source_type,
      tag: metric.tag
    },
    ...(additionalParams || {})
  };
  if (metric.parent_answer_id) {
    params.answer_id = metric.parent_answer_id;
  }
  Http.post('/express/api/ai_summaries', params).then(
    (http) => {
      if (http.response.status === 200) {
        goodCallback && goodCallback(http);
      } else {
        badCallback && badCallback(http);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback(reject);
    }
  );
};

const updateSummary = (id, goodCallback, badCallback, params = {}) => {
  Http.put(`/express/api/ai_summaries/${id}/update_summary`, params).then(
    (http) => {
      if (http.response.status === 200) {
        goodCallback && goodCallback(http);
      } else {
        badCallback && badCallback(http);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback(reject);
    }
  );
};

export { checkResult, checkTimeout, createSummary, updateSummary };
