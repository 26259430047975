import React, { createContext, useEffect, useState, useRef } from 'react';
import { filter, find, intersection, map } from "lodash";
import Modal from '../../../../../../common/components/modal';
import Http from "../../../../../../common/http";
import { urls } from '../const';
import KeywordDetails from "./keyword-details";
import KeywordsList from "./keywords-list";

export const KeywordsData = createContext({});

const Index = ({ metric, filters, closeConfigure }) => {
  const [ detailsForm, setDetailsForm ] = useState(false);
  const [ keywordForDetails, setKeywordForDetails ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ keywords, setKeywords ] = useState([]);
  const [ search, setSearch ] = useState('');

  const load = (q = '', callback) => {
    setLoading(true);
    const requestParams = {
      source_id: metric.source_id,
      source_type: metric.source_type,
      filters,
      q
    };
    if (metric.parent_answer_id) {
      requestParams.parent_q_name = metric.parent_name;
      requestParams.answer_id = metric.parent_answer_id;
    }
    Http.post(urls.keywordsList, requestParams).then(
      (http) => {
        if (http.response.status === 200) {
          setKeywords(http.data);
          if (callback) {
            callback();
          }
        }
        setLoading(false);
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const isKeywordsSelected = () => {
    return filter(keywords, (k) => (k.checked)).length >= 2;
  };

  const hiddenKeywordCount = () => {
    return filter(keywords, (k) => (k.hidden)).length;
  };

  const getChildren = (keyword) => {
    return filter(keywords, (el) => (!el.is_root && !!intersection(el.pids, keyword.ids).length));
  };

  const getChildrenOptions = (keyword) => {
    const kk = getChildren(keyword);
    const options = map(kk, (el) => ({ label: el.text, value: el.text }));
    let defaultOption = find(options, (el) => (el.value === keyword.text));
    if (!defaultOption) {
      defaultOption = { label: keyword.text, value: keyword.text };
      options.unshift(defaultOption);
    }
    return [ defaultOption, options ];
  };

  const updateKeywordText = (keyword, text, cancelEdit) => {
    if (!keyword.is_root) {
      return;
    }

    const params = {
      ids: keyword.ids,
      keyword: { text }
    };

    Http.put(
      `/dashboard/api/keywords.json`, params
    ).then(
      (http) => {
        if (http.response.status === 204) {
          keyword.text = text.toLowerCase();
          setKeywords([ ...keywords ]);
          cancelEdit();
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const unmerge = (keyword, callback) => {
    const params = {
      ids: keyword.ids
    };
    Http.post(
      `/dashboard/api/keywords/unmerge.json`, params
    ).then(
      (http) => {
        if (http.response.status === 204) {
          load(search, callback);
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const showKeywordDetails = (keyword) => {
    setKeywordForDetails(keyword);
    setDetailsForm(true);
  };

  const closeKeywordDetails = () => {
    setDetailsForm(false);
  };

  useEffect(() => {
    load();
  }, []);

  const value = {
    keywords,
    setKeywords,
    search,
    setSearch,
    load,
    unmerge,
    isKeywordsSelected,
    hiddenKeywordCount,
    getChildren,
    getChildrenOptions,
    showKeywordDetails,
    updateKeywordText
  };

  return (
    <KeywordsData.Provider value={ value }>
      <Modal modalSize="md" customZIndex={ 1080 } className="position-initial">
        {
          !detailsForm &&
          <KeywordsList
            metric={ metric }
            closeConfigure={ closeConfigure }
            loading={ loading }
          />
        }
        {
          detailsForm &&
          <KeywordDetails
            keyword={ keywordForDetails }
            closeConfigure={ closeConfigure }
            closeKeywordDetails={ closeKeywordDetails }
          />
        }
      </Modal>
    </KeywordsData.Provider>
  );
};

export default Index;
