import React, { useState, useContext, useEffect } from 'react';
import cn from "classnames";
import InfoToolTip from '../../infoToolTip';
import { HighLevelContext } from '../contexts';

const DisplayOnDedicatedPage = ({
  question, screenerQuestions, setScreenerQuestions, customMetricsDedicatedPage
}) => {
  const [ id ] = useState(() => (`Custom Question-DisplayDedicatedPage-${Date.now()}`));
  const { conceptsCountData } = useContext(HighLevelContext);
  const { display, disableDedicatedPage } = (conceptsCountData || {});
  const forceDedicatedPage = (
    customMetricsDedicatedPage ||
    (display && disableDedicatedPage) ||
    question.kind === 'Grid' ||
    question.survey_location !== 'default' ||
    question.open_end_show
  );

  const displayDedicatedPageCheck = () => (
    question.display_dedicated_page || forceDedicatedPage || false
  );
  const [ checked, setChecked ] = useState(displayDedicatedPageCheck);
  const updateValue = (newValue) => {
    setChecked(newValue);
    question.display_dedicated_page = newValue;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onCheck = (event) => {
    if (forceDedicatedPage) {
      return;
    }
    const newValue = !checked;
    updateValue(newValue);
  };

  useEffect(() => {
    const dedicatedValue = displayDedicatedPageCheck();
    if (dedicatedValue) {
      updateValue(dedicatedValue);
    }
  }, [ disableDedicatedPage, question.kind, question.survey_location, question.open_end_show ]);

  return (
    <>
      {
        (
          <div className="toggle -manage -dedicated-page" >
            <div className="form_section-title -grid">
              <div className="form_section-title-item -grid">
                <input
                  className="hide"
                  type="checkbox"
                  checked={ checked }
                  id={ id }
                  onChange={ () => {} }
                />
                <label
                  className={ cn("form-switch -display-dedicated-page", { "-disabled": forceDedicatedPage }) }
                  htmlFor={ id }
                  onClick={ onCheck }
                >
                  Display on a dedicated page
                </label>
              </div>
            </div>
            <div className="form_section-title -grid">
              <div className="form_section-title-item -grid">
                <span>Display on a dedicated page</span>
              </div>
              <InfoToolTip remote position="top-right">
                This custom metric will be displayed to respondent on a separate page.
                {
                  forceDedicatedPage &&
                  <>
                    {' '}
                    This toggle can not be switched off.
                  </>
                }
              </InfoToolTip>
            </div>
          </div>
        )
      }
    </>
  );
};

export default DisplayOnDedicatedPage;
