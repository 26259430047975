import React from "react";

const emptyStr = (str) => {
  return str.trim() === '';
};

const FormGroup = ({ title, name, type, currentDataItem, onChangeField }) => {
  return (
    <div className="form-group">
      <label className="form-label">
        {title}
      </label>
      {type === "textarea" ? (
        <textarea
          className={ `form-field -block ${emptyStr(currentDataItem[name]) ? 'has-error' : ''}` }
          value={ currentDataItem[name] }
          onChange={ (event) => onChangeField(event, name) }
          rows={ 3 }
        />
      ) : (
        <input
          type="text"
          className={ `form-field -block ${emptyStr(currentDataItem[name]) ? 'has-error' : ''}` }
          value={ currentDataItem[name] }
          onChange={ (event) => onChangeField(event, name) }
        />
      )}
      {emptyStr(currentDataItem[name]) && <div className="form-error">Please fill this field</div>}
    </div>
  );
};

export default FormGroup;
