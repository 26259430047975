import React, { useContext } from 'react';
import { includes } from 'lodash';
import { BaseContext } from '../../contexts';
import { sidebarBuzzbackTravelClick, sidebarVerizonTravelClick } from "../../../google-analytics/events";

const Travel = () => {
  const { initialData, paths, platform, gaTrackingId } = useContext(BaseContext);
  const showTravel = initialData.show_travel;
  const travelItems = initialData.travel_items;
  const buzzbackDashboardPath = paths.dashboard;
  const expressPath = paths.express;
  const customerCaseV2024Path = paths.verizon_customer_case_v2024;
  const catV2024Path = paths.verizon_cat_v2024;
  return (
    <>
      {
        showTravel &&
        <li className="sidebar_nav-list-item -active -with-inner -hide-highlight">
          Dashboard Travel
          <ul className="sidebar_nav-list -sub">
            {
              includes(travelItems, 'buzzback') &&
              <li className="sidebar_nav-list-item -sub">
                <a
                  className="sidebar_nav-link -sub"
                  href={ buzzbackDashboardPath }
                  onClick={ () => sidebarBuzzbackTravelClick(gaTrackingId, { platform: "express", page_name: "admin" }) }
                >
                  Buzzback
                </a>
              </li>
            }
            {
              includes(travelItems, 'express') && platform !== 'express' &&
              <li className="sidebar_nav-list-item -sub">
                <a
                  className="sidebar_nav-link -sub"
                  href={ expressPath }
                  onClick={ () => sidebarVerizonTravelClick('category', { platform: "express", page_name: "admin" }) }
                >
                  Express
                </a>
              </li>
            }
            {
              includes(travelItems, 'verizon_cit') &&
              <li className="sidebar_nav-list-item -sub">
                <a
                  className="sidebar_nav-link -sub"
                  href={ customerCaseV2024Path }
                  onClick={ () => sidebarVerizonTravelClick('category', { platform: "verizon_cit", page_name: "admin" }) }
                >
                  Verizon Customer Case
                </a>
              </li>
            }
            {
              includes(travelItems, 'verizon_cat') &&
              <li className="sidebar_nav-list-item -sub">
                <a
                  className="sidebar_nav-link -sub"
                  href={ catV2024Path }
                  onClick={ () => sidebarVerizonTravelClick('category', { platform: "verizon_cat", page_name: "admin" }) }
                >
                  Verizon CMI
                </a>
              </li>
            }
          </ul>
        </li>
      }
    </>
  );
};

export default Travel;
