import React, { useContext } from 'react';
import { filter } from 'lodash';
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import { urls } from './const';
import { PinnedData } from "./sentiment-list";
import { WordsData } from "./index";

const AnswerUnpinControl = ({ conceptId, metric, answer }) => {
  const { unpinTitle } = useContext(WordsData);
  const { pinnedIds, setPinnedIds } = useContext(PinnedData);

  const unlinkAnswer = () => {
    const url = urls.unlink.replace(':concept_id', conceptId);
    const requestParams = {
      source_id: metric.source_id,
      source_type: metric.source_type,
      linked_id: answer.id,
      linked_type: answer.type
    };
    if (metric.parent_answer_id) {
      requestParams.answer_id = metric.parent_answer_id;
    }

    Http.post(url, requestParams).then(
      (http) => {
        if (http.response.status === 200) {
          setPinnedIds(filter(pinnedIds, (id) => (id != answer.id)));
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  return (
    <div className="sentiment_control">
      <span
        className="pin-button icon-link -gray -no-text"
        onClick={ unlinkAnswer }
        role="button"
        title={ unpinTitle }
      >
        <SvgIcon className="icon-link_icon" name="i-pin-fill" />
        <span className="icon-link_text">{unpinTitle}</span>
      </span>
    </div>
  );
};

export default AnswerUnpinControl;
