import React, { useState } from 'react';
import cn from "classnames";

const ToggleFivePtScale = ({
  question, questions, setQuestions, bold,
  manageFivePtToggle, fivePtScaleMode, setFivePtScaleMode
}) => {
  const [ id ] = useState(() => (`Custom Question-${Date.now()}`));

  const onManageUpdate = (event) => {
    if (!manageFivePtToggle) {
      return;
    }
    const newValue = !fivePtScaleMode;
    setFivePtScaleMode(newValue);
    question.as_point_scale = newValue;
    setSpecificAnswersOrder(newValue);
    uncheckNoneAndOtherAnswers(newValue);
    setQuestions([ ...questions ]);
  };

  const setSpecificAnswersOrder = (newValue) => {
    if (newValue) {
      question.answers_order = 'specific';
    }
  };

  const uncheckNoneAndOtherAnswers = (newValue) => {
    if (newValue) {
      question['none_answer'] = false;
      question['other_answer'] = false;
    }
  };

  const optionsAmount = (question.kind === 'Grid' ? question.scales.length : question.answers.length);

  return (
    <>
      {
        (
          <div className="toggle -manage -five-point-scale" >
            <div className="form_section-title -grid">
              <div className="form_section-title-item -grid">
                <input
                  className="hide"
                  type="checkbox"
                  checked={ fivePtScaleMode }
                  id={ id }
                  onChange={ () => {} }
                />
                <label className={ cn("form-switch", { '-disabled': !manageFivePtToggle }) } htmlFor={ id } onClick={ onManageUpdate } >
                  {`Toggle ${optionsAmount}-point scale question`}
                </label>
              </div>
            </div>
            <div className="form_section-title -grid">
              <div className={ cn("form_section-title-item -grid", { '-disabled': !manageFivePtToggle }) }>
                <div className={ cn({ 'form_section-text -bold': bold }) }>{`${optionsAmount} - point scale question`}</div>
              </div>
              <div className="form_section-title-item -grid">
                <div className="info-tooltip">
                  <div className="info-tooltip_control">
                    <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
                  </div>
                  <div className="info-tooltip_drop -right">
                    <div className="info-tooltip_drop-inner">
                      {`With this setting turned on, reporting will be adjusted to match other ${optionsAmount} point scale key metric questions. The first answer option in the list below should be top box, and the ${optionsAmount}th answer option should be bottom box`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default ToggleFivePtScale;
