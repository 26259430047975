import React, { useState, useEffect } from 'react';
import { map, each, isEmpty, keys, find, trim }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { getAgeOptions, getAgeAnswerId } from '../logic/age.js';
import FooterButton from './footer_button.js';
import ZoomImage from "./zoom_image";
import Grid from "./metrics/grid";

const OptionTextOutput = ({ label, multi }) => (
  <div
    className={ multi ? "checkbox_label" : "radio_label" }
    { ...renderRawHtml(label) }
  />
);

const OptionTextWithImageOutput = ({ label, imageUrl }) => (
  <div className="label-with-image">
    {
      !!imageUrl &&
      <div
        className={
          classnames(
            "label-image",
            { "-full-width": (!imageUrl || !label) },
            { '-no-border': !label }
          )
        }
      >
        <img src={ imageUrl } alt={ label } />
      </div>
    }
    {
      !!label &&
      <div className={ classnames("label-text", { "-full-width": (!imageUrl || !label) }) }>
        <div className="label-center" { ...renderRawHtml(label) }  />
      </div>
    }
  </div>
);

const OptionLabelOutput = ({ answer, multi, withImages, images }) => {
  const label = answer.label;
  const findImageByAnswerId = () => find(images, (el) => el.id === answer.id);
  const imageUrl = withImages ? ((images || {})[answer.id] || findImageByAnswerId()?.image_url) : null;
  return (
    <>
      {
        withImages ?
          <OptionTextWithImageOutput
            label={ label }
            imageUrl={ imageUrl }
          /> :
          <OptionTextOutput
            label={ label }
            multi={ multi }
          />
      }
    </>
  );
};

const Screening = ({
  question, nextStep, result
}) => {
  const { kids, two_columns, placeholder } = question;

  const questionText = question.text;
  const customScreening = question.custom_screening;
  const single = question.kind === 'Single';
  const multi = question.kind === 'Multi';
  const grid = question.kind === 'Grid';
  const needDropDown = question.need_dropdown;
  const isAge = question.is_age;
  const answers = question.detailed_answers || question.answers;
  const withAnswerImages = question.has_images || !!(question.answer_images || []).length;
  const answerImages = question.answer_images;

  const [ radioAnswer, updateRadioAnswer ] = useState(null);
  const [ dropDownAnswer, updateDropDownAnswer ] = useState(null);
  const [ disabled, updateDisabled ] = useState(true);
  const [ checkboxAnswers, updateCheckboxAnswers ] = useState({});
  const [ gridAnswers, updateGridAnswers ] = useState({});
  const [ otherAnswer, setOtherAnswer ] = useState('');

  const isOther = (id) => ((id || '').match(/_-2/));
  const isNone = (id) => ((id || '').match(/_-1/));
  const hasOther = find(answers, (answer) => isOther(answer.id));
  const isRadioOtherSelected = () => (isOther(radioAnswer));
  const isCheckboxOtherSelected = () => (
    find(keys(checkboxAnswers), (item) => isOther(item))
  );

  const getImage = (id) => (
    answerImages?.find((item) => item?.id === id)?.image_url
  );

  const updateDisabledByKind = (kind) => {
    let newDisabled;
    let otherSelected;
    let enableWhenPresent;

    if (kind === 'radio') {
      otherSelected = isRadioOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = !radioAnswer || !enableWhenPresent;
    } else if (kind === 'checkbox') {
      otherSelected = isCheckboxOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = isEmpty(checkboxAnswers) || !enableWhenPresent;
    } else if (kind === 'grid') {
      newDisabled = isEmpty(gridAnswers) || keys(gridAnswers).length < answers.length;
    } else {
      newDisabled = !dropDownAnswer;
    }
    updateDisabled(newDisabled);
  };

  const updateOtherText = (event) => {
    setOtherAnswer(event.target.value);
  };

  useEffect(() => {
    updateDisabledByKind('checkbox');
  }, [ checkboxAnswers ]);
  useEffect(() => {
    updateDisabledByKind('dropDown');
  }, [ dropDownAnswer ]);
  useEffect(() => {
    updateDisabledByKind('radio');
  }, [ radioAnswer ]);
  useEffect(() => {
    updateDisabledByKind('grid');
  }, [ gridAnswers ]);

  useEffect(() => {
    if (multi) {
      updateDisabledByKind('checkbox');
    } else {
      updateDisabledByKind('radio');
    }
  }, [ otherAnswer ]);

  const updateCheckbox = (value) => {
    updateCheckboxAnswers({ ...value });
  };
  const updateDropDown = (value) => {
    updateDropDownAnswer(value);
  };
  const updateRadio = (value) => {
    updateRadioAnswer(value);
  };

  const saveOtherAnswer = () => {
    const shouldSaveOther = multi ?
      isCheckboxOtherSelected() : isRadioOtherSelected();
    if (shouldSaveOther) {
      if (!result.otherAnswers) {
        result.otherAnswers = {};
      }
      result.otherAnswers[question.name] = otherAnswer;
    }
  };

  const nextQuestion = () => {
    let realId, realAnswer;

    if (multi) {
      each(keys(checkboxAnswers), (key) => {
        if (checkboxAnswers[key]) {
          result.answers.push(key);
        }
      });
      updateCheckbox({});
    } else if (needDropDown && isAge) {
      result.answers.push(getAgeAnswerId(question, dropDownAnswer));
      result.selectedAge = dropDownAnswer;
      updateDropDown(null);
    } else if (needDropDown && !isAge) {
      realId = find(answers, (el) => el.id === dropDownAnswer) || {};
      realAnswer = realId.mapped_id || realId.id || dropDownAnswer;
      result.answers.push(realAnswer);
      updateDropDown(null);
    } else if (grid) {
      each(keys(gridAnswers), (key) => {
        const answer = `${key}_${gridAnswers[key]}`;
        result.answers.push(answer);
      });
      updateGridAnswers(null);
    } else {
      realId = find(answers, (el) => el.id === radioAnswer) || {};
      realAnswer = realId.mapped_id || realId.id || radioAnswer;
      result.answers.push(realAnswer);
      updateRadio(null);
    }
    saveOtherAnswer();
    setOtherAnswer('');
    nextStep();
  };

  const radioChange = (value) => {
    updateRadio(value);
  };
  const checkboxChange = (value, answerId) => {
    if (value) {
      if (isNone(answerId)) {
        each(checkboxAnswers, (v, k) => {
          delete checkboxAnswers[k];
        });
      }
      checkboxAnswers[answerId] = value;
    } else {
      delete checkboxAnswers[answerId];
    }
    updateCheckbox(checkboxAnswers);
  };

  const dropDownChange = (event) => {
    updateDropDown(event.target.value);
  };

  const disableCheckboxItem = (answerId) => {
    if (isNone(answerId)) {
      return false;
    }
    return !!find(checkboxAnswers, (v, k) => {
      return isNone(k);
    });
  };

  const withDropDown = () => (
    <div className="survey-options js-select">
      <select
        className="form-field"
        onChange={ (event) => { dropDownChange(event); } }
      >
        <option value="">{ placeholder }</option>
        {
          isAge &&
          map(getAgeOptions(question), (answer) => (
            <option value={ answer } key={ answer }>
              { answer }
            </option>
          ))
        }
        {
          !isAge &&
          map(answers, (answer) => (
            <option value={ answer.id } key={ answer.id }>
              { answer.label }
            </option>
          ))
        }
      </select>
    </div>
  );

  const textItemOutput = (answerId) => (
    !question.custom ||
      (question.custom && withAnswerImages && two_columns) ||
      (!getImage(answerId) && !withAnswerImages) ||
      isOther(answerId) ||
      isNone(answerId)
  );

  const imageItemOutput = (answerId) => (
    question.custom && (withAnswerImages && !two_columns) && !isOther(answerId) && !isNone(answerId)
  );

  const singleOutput = () => (
    <ul
      className={
        classnames(
          "survey-options_list",
          { "-full-width": hasOther || withAnswerImages },
          { '-with-images': withAnswerImages },
          { '-custom': question.custom }
        )
      }
    >
      {
        map(answers, (answer) => (
          <li
            key={ answer.id }
            className={
              classnames(
                { '-none': isNone(answer.id), '-other': isOther(answer.id) },
                { '-has-other': isNone(answer.id) && hasOther },
                { 'option-top-border': isNone(answer.id) || (isOther(answer.id) && withAnswerImages) },
                { 'option-full-width': isOther(answer.id) }
              )
            }
          >
            <div
              className={
                classnames(
                  "option-list-inner",
                  { "-more-right-padding": withAnswerImages },
                  { "-no-left-right-padding": isOther(answer.id) || isNone(answer.id) }
                )
              }
            >
              {
                textItemOutput(answer.id) &&
                <label
                  className={
                    classnames(
                      "radio text-answer-label",
                      { "-full-width": isOther(answer.id) || withAnswerImages }
                    )
                  }
                >
                  <input
                    type="radio"
                    name={ question.name }
                    value={ answer.id }
                    checked={ answer.id === radioAnswer }
                    onChange={ () => { radioChange(answer.id); } }
                  />
                  {
                    isOther(answer.id) &&
                    <div
                      className={
                        classnames(
                          "radio_label -centered",
                          { "-full-width": isOther(answer.id) }
                        )
                      }
                    >
                      <div className="other-text_wrapper">
                        <div className="other-text_title" { ...renderRawHtml(`${answer.label}:`) } />
                        <input
                          type="text"
                          className="js-other-text form-field -other-text"
                          // disabled={ !radioOptionIsChecked(answer.id) }
                          onFocus={ () => { radioChange(answer.id); } }
                          value={ otherAnswer }
                          onChange={ updateOtherText }
                        />
                      </div>
                    </div>
                  }
                  { isNone(answer.id) && <OptionLabelOutput answer={ answer } /> }
                  {
                    !isOther(answer.id) && !isNone(answer.id) &&
                    <OptionLabelOutput
                      answer={ answer }
                      withImages={ withAnswerImages }
                      images={ answerImages }
                    />
                  }
                </label>
              }
              {
                imageItemOutput(answer.id) &&
                <div className="radio-plate -base" >
                  <div className="radio-plate_part -no-padding -right-margin -clickable">
                    <label className="radio">
                      <input
                        id={ `${question.id}_${answer.id}` }
                        type="radio"
                        name="concept"
                        value={ answer.id }
                        checked={ answer.id === radioAnswer }
                        onChange={ () => { radioChange(answer.id); } }
                      />
                      <div className="radio_label -no-text" />
                    </label>
                  </div>
                  <div className="radio-plate_part-image">
                    <label
                      className="-pointer"
                      htmlFor={ `${question.id}_${answer.id}` }
                      { ...renderRawHtml(answer.label) }
                    />
                    {
                      !!getImage(answer.id) &&
                      <div className="survey-options_image-plate -fix-width">
                        <ZoomImage
                          className="radio-plate_part-image"
                          popupClassName="-custom-image-in-modal"
                          src={ getImage(answer.id) }
                        >
                          <img src={ getImage(answer.id) } alt="image" />
                        </ZoomImage>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </li>
        ))
      }
    </ul>
  );

  const multiOutput = () => (
    <ul
      className={
        classnames(
          "survey-options_list",
          { "-full-width": hasOther || withAnswerImages },
          { '-with-images': withAnswerImages },
          { '-custom': question.custom }
        )
      }
    >
      {
        map(answers, (answer) => (
          <li
            key={ answer.id }
            className={
              classnames(
                { '-none': isNone(answer.id), '-other': isOther(answer.id) },
                { '-has-other': isNone(answer.id) && hasOther },
                { 'option-top-border': isNone(answer.id) || (isOther(answer.id) && withAnswerImages) },
                { 'option-full-width': isOther(answer.id) }
              )
            }
          >
            <div
              className={
                classnames(
                  {
                    "option-list-inner": !isNone(answer.id),
                    "-more-right-padding": withAnswerImages,
                    "-no-left-right-padding": isOther(answer.id) || isNone(answer.id)
                  }
                )
              }
            >
              {
                textItemOutput(answer.id) &&
                <label
                  className={
                    classnames(
                      "checkbox text-answer-label",
                      { "-full-width": isOther(answer.id) || withAnswerImages },
                    )
                  }
                >
                  <input
                    type="checkbox"
                    disabled={ disableCheckboxItem(answer.id) }
                    checked={ !!checkboxAnswers[answer.id] }
                    onChange={ (event) => { checkboxChange(event.target.checked, answer.id); } }
                  />
                  {
                    isOther(answer.id) &&
                    <div
                      className={
                        classnames(
                          "checkbox_label -centered",
                          { "-full-width": isOther(answer.id) }
                        )
                      }
                    >
                      <div className="other-text_wrapper">
                        <div className="other-text_title" { ...renderRawHtml(`${answer.label}:`) } />
                        <input
                          type="text"
                          className="js-other-text form-field -other-text"
                          disabled={ disableCheckboxItem(answer.id) }
                          onFocus={ () => { checkboxChange(true, answer.id); }  }
                          value={ otherAnswer }
                          onChange={ updateOtherText }
                        />
                      </div>
                    </div>
                  }
                  {
                    isNone(answer.id) &&
                    <OptionLabelOutput multi answer={ answer } />
                  }
                  {
                    !isOther(answer.id) && !isNone(answer.id) &&
                    <OptionLabelOutput
                      multi
                      answer={ answer }
                      withImages={ withAnswerImages }
                      images={ answerImages }
                    />
                  }
                </label>
              }
              {
                imageItemOutput(answer.id) &&
                <div className="radio-plate -base" >
                  <div className="radio-plate_part -no-padding -clickable">
                    <label className="checkbox">
                      <input
                        id={ `${question.id}_${answer.id}` }
                        type="checkbox"
                        disabled={ disableCheckboxItem(answer.id) }
                        checked={ !!checkboxAnswers[answer.id] }
                        onChange={ (event) => { checkboxChange(event.target.checked, answer.id); } }
                      />
                      <div className="checkbox_label -no-text" />
                    </label>
                  </div>
                  <div className="radio-plate_part-image">
                    <label
                      className={ '-pointer' }
                      htmlFor={ `${question.id}_${answer.id}` }
                      { ...renderRawHtml(answer.label) }
                    />
                    {
                      !!getImage(answer.id) &&
                      <div className="survey-options_image-plate -fix-width">
                        <ZoomImage
                          className="radio-plate_part-image"
                          popupClassName="-custom-image-in-modal"
                          src={ getImage(answer.id) }
                        >
                          <img src={ getImage(answer.id) } alt="image" />
                        </ZoomImage>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </li>
        ))
      }
    </ul>
  );

  const withoutDropDown = () => (
    <div
      className={
        classnames(
          "survey-options",
          { "-screening": question.special_view },
          { "-grid": grid },
          { "-with-images": withAnswerImages }
        )
      }
    >
      { single && singleOutput() }
      { multi && multiOutput() }
      {
        grid &&
        <Grid
          question={ question }
          gridAnswers={ gridAnswers }
          updateGridAnswers={ updateGridAnswers }
          updateStateAndSend={ () => updateDisabledByKind("grid") }
        />
      }
    </div>
  );

  return (
    <>
      <div className="survey-layout_content">
        <div className="survey-options_question -one-question">
          <div
            className={
              classnames(
                "survey-options_question-title  -center",
                { "custom-title -screening": customScreening }
              )
            }
          >
            <div className="survey-options_question-title-inner" { ...renderRawHtml(questionText) } />
          </div>
        </div>
        <div
          className={
            classnames(
              "survey-layout_container",
              "-one-question",
              {
                "-kids": kids,
                "-two-columns": two_columns && !grid
              }
            )
          }
        >
          { needDropDown && withDropDown() }
          { !needDropDown && withoutDropDown() }
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton
          nextStep={ () => { nextQuestion(); } }
          options={ { disabled } }
        />
      </div>
    </>
  );
};

export default Screening;
