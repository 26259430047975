import React, { useState } from "react";
import { map, cloneDeep, find, isEmpty } from "lodash";
import Modal from '../../../../common/components/modal';
import SvgIcon from "../../../../common/components/svg-icon";
import FormGroup from "./formGroup";

const themeLimit = 6;

const emptyStr = (str) => {
  return str.trim() === '';
};

const SummaryEditor = ({ summaryDataInit, cancelModal, saveModalData, disabled }) => {
  const [ summaryData, setSummaryData ] = useState(() => {
    if (isEmpty(summaryDataInit)) {
      return [ ];
    }
    return Object.values(cloneDeep(summaryDataInit));
  });

  const [ currentKey, setCurrentKey ] = useState(0);

  const currentDataItem = summaryData[currentKey];

  const newSummaryItem = {
    long_summary: "",
    name: "New Theme"
  };

  const onChangeField = (event, name) => {
    const newArr = [ ...summaryData ];
    newArr[currentKey][name] = event.target.value;
    setSummaryData(newArr);
  };

  const deleteTheme = (key) => {
    const newArr = [ ...summaryData ];
    newArr.splice(key, 1);
    setSummaryData(newArr);
    setCurrentKey(0);
  };

  const changeCurrentTheme = (key) => {
    setCurrentKey(key);
  };

  const addTheme = () => {
    setSummaryData([ ...summaryData, newSummaryItem ]);
    setCurrentKey(summaryData.length);
  };

  const someFieldsEmpty = (data) => {
    return find(data, (item) => emptyStr(item.name) || emptyStr(item.long_summary));
  };

  return (
    <Modal
      modalClassName="centered"
      modal
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title express">
            AI Summary
          </h3>
          <ul className="summary-editor_tabs">
            {
              map(summaryData, (item, key) => (
                <li key={ key } className={ `summary-editor_tab` }>
                  <button
                    className={ `button ${currentKey === key ? '' : '-secondary'}` }
                    onClick={ () => changeCurrentTheme(key) }
                  >
                    { item.name }
                  </button>
                </li>
              ))
            }
            {summaryData.length < themeLimit && (
              <li className={ `summary-editor_tab` }>
                <button
                  className={ `button` }
                  onClick={ addTheme }
                >
                  <SvgIcon name="plus" />
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="summary-editor_body">
          {currentDataItem &&
            <div className="summary-editor_form express">
              <div className="summary-editor_form-line">
                <div className="summary-editor_form-grid">
                  <div className="summary-editor_form-grid-item">
                    <FormGroup title="Theme" name="name" currentDataItem={ currentDataItem } onChangeField={ onChangeField } />
                  </div>
                </div>
              </div>
              <div className="summary-editor_form-line">
                <FormGroup title="Long Summary" name="long_summary" type="textarea" currentDataItem={ currentDataItem } onChangeField={ onChangeField } />
              </div>
              <div className="summary-editor_form-buttons -right">
                <button
                  type="button"
                  className="button -branded -warning"
                  onClick={ () => deleteTheme(currentKey) }
                >
                  Delete Theme
                </button>
              </div>
            </div>
          }
        </div>

        <div className="summary-editor_footer express">
          <div className="summary-editor_footer-buttons -right">
            <button
              className="button -secondary"
              type="button"
              onClick={ cancelModal }
              disabled={ disabled }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ () => { saveModalData(summaryData); } }
              disabled={ disabled || someFieldsEmpty(summaryData) || summaryData.length === 0 }
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SummaryEditor;
